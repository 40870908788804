html,
body,
#root {
  height: 100%;
  color: #353535;
  // line-height: 16px;
  // color: rgba(0, 0, 0, 0.87);
  font-family: 'Hind', sans-serif;
  // overflow: auto;
  // background-color: #f4f6f8;

  // SCROLL THINGS
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.hide-vertical-scrollbar {
  ::-webkit-scrollbar {
    width: 0px !important;
    // height: 5px;
  }
}

.df-row {
  display: flex;
  flex-direction: row;
}

.fw {
  flex-wrap: wrap;
}

.df-row-ac {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.df-col-if-mobile {
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.df-col-ac-if-mobile {
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.hide-if-mobile {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.df-col-reverse-if-mobile {
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.df-row-ab {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.df-row-afs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.df-row-afs-left {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
}

.df-row-afs-right {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-end;
}

.df-col-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.df-col-ae {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.df-col-as {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.df-col-ab {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.df-col {
  display: flex;
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: flex-end;
}

.jc-se {
  justify-content: space-evenly;
}

.sticky-at-top {
  position: sticky;
  top: 0;
}

.sticky-at-bottom {
  position: sticky;
  bottom: 0;
}

.stretch-ver {
  height: 100%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.h-fc {
  height: fit-content;
}

.stretch-hor {
  width: 100%;
}

.tac {
  text-align: center;
}

.fg1 {
  flex-grow: 1;
}

.fb0 {
  flex-basis: 0;
}

.font-bold {
  font-weight: 600;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.ml-10px {
  margin-left: 10px;
}

.mr-5px {
  margin-right: 5px;
}

.mr-20px {
  margin-right: 20px;
}

.z-index-1 {
  z-index: 1;
}

.overflow-auto {
  overflow: auto;
}

.padding-10px {
  padding: 10px;
}

.jc-c-if-mobile {
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}

.hidden {
  visibility: hidden;
}

.auto-complete-with-add {
  .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment {
      right: 40px,
  }
}

.icon-wrapper {
  svg {
      font-size: inherit;
      color: inherit;
  }
}

.avatar-icon-wrapper {
  svg {
    width: inherit;
    height: inherit;
  }
}

.step-warning {
  .Mui-error {
      color: rgb(255, 152, 0) !important;
  }
}

.forgot-password-button {
  &:hover {
      text-decoration: underline;
  }
}

.remove-inner-span-padding {
  span {
    padding: 0 !important;
  }
}

.small-date-picker {
  .MuiPickersDay-dayWithMargin {
      width: 31px;
      height: 31px;
  }
  .MuiPickersCalendar-weekDayLabel {
      width: 31px;
      height: 31px;
  }
  .MuiPickersBasePicker-pickerView {
      width: fit-content;
      margin-left: 8px;
  }
  .MuiPickersCalendarHeader-root {
      padding-left: 14px;
      padding-right: 2px;
  }
  .MuiPickersCalendar-root {
      min-height: 200px;
  }
  .MuiPickersStaticWrapper-root {
      min-width: 0;
  }
}

.wrap {
  white-space: pre-line;
}